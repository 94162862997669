import React from "react"
import Layout from "components/layout"
import HomeLoansLandingPage from "components/content/home-loans-external-landing-page"

const LandingPage = (props: any) => {
  return (
    <Layout 
      title="Home Loan Comparison | Stay or Go"
      description="Compare home loan offers from all the major banks and popular brands."
      image="/video/StayOrGo_Animation_01.jpf"
      urlSlug="home-loans-landing"
    >
      <HomeLoansLandingPage />
    </Layout>
  )
}

export default LandingPage
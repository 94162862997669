// extracted by mini-css-extract-plugin
export var assessmentFactBox = "styles-module--assessment-fact-box--b98c6";
export var assessmentFactBoxesGrid = "styles-module--assessment-fact-boxes-grid--b3381";
export var contentBoxContainer = "styles-module--content-box-container--90c0b";
export var contentBoxContentContainer = "styles-module--content-box-content-container--9a56a";
export var contentBoxNumberContainer = "styles-module--content-box-number-container--99a3a";
export var contentBoxNumberText = "styles-module--content-box-number-text--24abc";
export var getStartedContainer = "styles-module--get-started-container--b9cfd";
export var getStartedHelpText = "styles-module--get-started-help-text--1ebf0";
export var headingHighlightBackground = "styles-module--heading-highlight-background--5aa7f";
export var headingHighlightContainer = "styles-module--heading-highlight-container--ed111";
export var headingHighlightText = "styles-module--heading-highlight-text--200fb";
export var joinerDotStyle = "styles-module--joiner-dot-style--797f1";
export var lastContentSectionContainer = "styles-module--last-content-section-container--316af";
export var lookingForFirstLoanContainer = "styles-module--looking-for-first-loan-container--3e06a";
export var newToStayOrGoContainer = "styles-module--new-to-stay-or-go-container--d2906";
export var pageContentContainer = "styles-module--page-content-container--98199";
export var sectionTextDivider = "styles-module--section-text-divider--72b69";
export var tickLine = "styles-module--tick-line--9292b";
export var topSectionImageContainerTablet = "styles-module--top-section-image-container-tablet--b1080";
export var topSectionImageContentContainerDesktop = "styles-module--top-section-image-content-container-desktop--cd2cf";
export var topSectionImageStyle = "styles-module--top-section-image-style--fe9d6";
export var topSectionTextContainerTablet = "styles-module--top-section-text-container-tablet--46d50";
export var topSectionTextContentContainerDesktop = "styles-module--top-section-text-content-container-desktop--89d04";
import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Typography } from "components/typography"
import { HomeLoanTopPicks as TopPicks } from "components/content/top-picks"
import * as Clickable from "components/clickable"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import AppManager from "services/app-manager"
import { HomeLoanDisclaimer } from "components/content/content-disclaimer"
import { ListHeaderProvider } from "components/content/list-header"
import { compileSchemaOrgQa } from "components/schema"
import ReactPlayer from "react-player"
import Faq from "components/content/faq"
import { chunkifyArray } from "components/utils"
import { MapToDiv } from "components/content/map-to-div"
import ReturnLinkChain from "components/content/return-link-chain"
import * as styles from "./styles.module.scss"
import FeaturedIn from "components/content/featured-in"
import { ArrowForwardIos } from "@mui/icons-material"
import { LazyLoadImage } from "react-lazy-load-image-component"

export const HomeLoansLandingPageView = (props: { indexPage?: boolean }) => {
  const isWide = useMediaQuery("(min-width:768px)")
  const isTablet = !useMediaQuery("(min-width:1024px)")

  const chunkSize = isWide ? 4 : 2
  const features = [
    { name: "Best Rate", urlSlug: "best-home-loan-rates", img: "/images/icon_best_rate.png" },
    { name: "Owner Occupied", urlSlug: "owner-occupied-home-loans", img: "/images/icon_home.png" },
    { name: "Investment", urlSlug: "investment-home-loans", img: "/images/icon_investment.png" },
    { name: "Fixed Rate", urlSlug: "fixed-rate-home-loans", img: "/images/icon_fixed_rate.png" },
    { name: "Variable Rate", urlSlug: "variable-rate-home-loans", img: "/images/icon_variable_rate.png" },
    { name: "Low Deposit", urlSlug: "low-deposit-home-loans", img: "/images/icon_low_deposit.png" },
    { name: "Interest Only", urlSlug: "interest-only-home-loans", img: "/images/low_rate.png" },
    { name: "Cashback", urlSlug: "home-loan-cashback-offers", img: "/images/cash_back.png" },
    // { name: "Offset", urlSlug: "offset-home-loans", img: "/images/icon_offset.png" },
  ]
  const featureLines = chunkifyArray(features, chunkSize)

  const hlProviders = AppManager.getInstance().getReducedHomeLoanProviders()
  const mainItems = Object.values(hlProviders)
    .filter((x: any) => x.big4 && x.popular && x.hasProduct)
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const otherItems = Object.values(hlProviders)
    .filter((x: any) => !x.big4 && (!props.indexPage || x.popular) && x.hasProduct)
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const majors = chunkifyArray(mainItems, chunkSize)
  const popular = chunkifyArray(otherItems, chunkSize)

  // const queryResult = useStaticQuery(graphql`
  //   {
  //     allContentfulContentQa {
  //       nodes {
  //         slug
  //         categoryName
  //         categorySummary
  //         qa {
  //           qa
  //         }
  //       }
  //     }
  //   }
  // `)
  // const qa = queryResult.allContentfulContentQa.nodes.find(x => x.slug === "/home-loans")
  // const qaList: [] = queryResult.allContentfulContentQa.nodes.filter((x: any) => x.slug.match(/^\/home-loans\/\d+\//) !== null)
  // // const qaList: [] = queryResult.allContentfulContentQa.nodes.filter((x: any) => x.slug.indexOf("/faq-test/") === 0)
  // qaList.sort((a: any, b: any) => {
  //   const orderA = parseInt(a.slug.split("/")[2])
  //   const orderB = parseInt(b.slug.split("/")[2])
  //   return (isNaN(orderA) ? Number.MAX_VALUE : orderA) - (isNaN(orderB) ? Number.MAX_VALUE : orderB)
  // })

  // const faqContent: any[] = []
  // qaList.forEach((x: any) => {
  //   const slugEnding: string = x.slug.split("/")[3]
  //   if (x.categoryName) {
  //     faqContent.push({
  //       categoryName: x.categoryName,
  //       categoryDescription: x.categorySummary || "",
  //       categoryContentMarkdown: x.qa.qa
  //     })
  //   // } else if (slugEnding in faqCategoryDetails) {
  //   //   faqContent.push({
  //   //     categoryName: faqCategoryDetails[slugEnding].name,
  //   //     categoryDescription: faqCategoryDetails[slugEnding].description,
  //   //     categoryContentMarkdown: x.qa.qa
  //   //   })
  //   // }
  //   // else {
  //   //   // TEST
  //   //   faqContent.push({
  //   //     categoryName: slugEnding,
  //   //     categoryDescription: "Test",
  //   //     categoryContentMarkdown: x.qa.qa
  //   //   })
  //   }
  // })

  const topSection = () => {
    return !isTablet ? (
      <div style={{ display: "flex" }}>
        <div className={styles.topSectionTextContentContainerDesktop}>
          <Typography.H1>
            <div style={{ color: "#00AFC5" }}>
              Get a{" "}
              <span className={styles.headingHighlightContainer}>
                <span className={styles.headingHighlightText}>better</span>
                <div className={styles.headingHighlightBackground} />
              </span>
              <br />
              home loan
            </div>
          </Typography.H1>
          <div style={{ height: "40px" }} />
          <Typography.P comp="external-landing-page-top-text">
            <span style={{ color: "#4D4D4D" }}>Before you refinance, see what your bank will offer you to stay. Compare leading offers from across the market.</span>
          </Typography.P>
          <div className={styles.getStartedContainer}>
            <Clickable.Text
              comp={1}
              component={Link}
              to={"/home-loans/instructions/"}
              className="landing-page-button"
              style={{ width: "100%" }}
              onClick={() => {
                DataCollector.getInstance().addAction(DataCollectorAction.GET_STARTED, { buttonId: "HOME_LOANS", productCategory: "HOME_LOANS" })
              }}
            >
              <div style={{ margin: "auto" }}>
                Get Started <ArrowForwardIos style={{ fontSize: "50%" }} />
              </div>
            </Clickable.Text>
            <Typography.P comp="small">
              <div className={styles.getStartedHelpText}>It's easy, and takes just a few minutes!</div>
            </Typography.P>
          </div>
        </div>
        <div className={styles.topSectionImageContentContainerDesktop}>
          <LazyLoadImage src="/images/home_loans/landing-page-top-image.jpg" style={{width: "100%", height: "100%"}} className={styles.topSectionImageStyle} />
        </div>
      </div>
    ) : (
      <div style={{ position: "relative" }}>
        <div className={styles.topSectionImageContainerTablet}>
          <LazyLoadImage src="/images/home_loans/landing-page-top-image.jpg" style={{width: "100%", height: "100%"}} className={styles.topSectionImageStyle} />
        </div>
        <div className={styles.topSectionTextContainerTablet}>
          <Typography.H1>
            <div style={{ color: "#00AFC5", textAlign: "center" }}>
              Get a{" "}
              <span className={styles.headingHighlightContainer}>
                <span className={styles.headingHighlightText}>better</span>
                <div className={styles.headingHighlightBackground} />
              </span>
              <br />
              home loan
            </div>
          </Typography.H1>
          <div style={{ height: "40px" }} />
          <Typography.P comp="external-landing-page-top-text">
            <div style={{ color: "#202020", textAlign: "center" }}>Before you refinance, see what your bank will offer you to stay. Compare leading offers from across the market.</div>
          </Typography.P>
          <div className={styles.getStartedContainer}>
            <Clickable.Text
              comp={1}
              component={Link}
              to={"/home-loans/instructions/"}
              className="landing-page-button"
              style={{ width: "100%" }}
              onClick={() => {
                DataCollector.getInstance().addAction(DataCollectorAction.GET_STARTED, { buttonId: "HOME_LOANS", productCategory: "HOME_LOANS" })
              }}
            >
              <div style={{ margin: "auto" }}>
                Get Started <ArrowForwardIos style={{ fontSize: "50%" }} />
              </div>
            </Clickable.Text>
            <Typography.P comp="small">
              <div className={styles.getStartedHelpText}>It's easy, and takes just a few minutes!</div>
            </Typography.P>
          </div>
        </div>
      </div>
    )
  }

  const sectionTextDivider = (heading: string, text: string, style?: React.CSSProperties) => {
    return (
      <div className={styles.sectionTextDivider} style={{ ...style }}>
        <Typography.H2 comp="external-landing-page-section-heading">{heading}</Typography.H2>
        <Typography.P comp="large">{text}</Typography.P>
      </div>
    )
  }

  const joinerDot = () => <circle className={styles.joinerDotStyle} />

  const contentBox = (imageUrl: string, heading: string, text: string, index: number) => {
    return (
      <div className={styles.contentBoxContainer}>
        <circle className={styles.contentBoxNumberContainer}>
          <div className={styles.contentBoxNumberText}>{index}</div>
        </circle>
        <div className={styles.contentBoxContentContainer}>
          <LazyLoadImage src={imageUrl} style={{height: "75px"}} />
          <div style={{ height: "40px" }} />
          <Typography.P comp="medium-bold">
            <div style={{ marginBottom: "10px" }}>{heading}</div>
          </Typography.P>
          <Typography.P comp="small">{text}</Typography.P>
        </div>
      </div>
    )
  }

  const simplifySearchSection = () => {
    return (
      <div style={{ display: "flex", flexDirection: isWide ? "row" : "column", justifyContent: "space-between", alignItems: "stretch", marginTop: "60px" }}>
        {contentBox("/images/icon_home_loan.png", "Tell us the basics", "Answer a few simple questions about your current home loan", 1)}
        <div style={isWide ? { width: "11px" } : { height: "16px" }} />
        {joinerDot()}
        {joinerDot()}
        {joinerDot()}
        {joinerDot()}
        {isWide && joinerDot()}
        <div style={isWide ? { width: "11px" } : { height: "16px" }} />
        {contentBox("/images/icon_search.png", "We search for a better deal", "See what your lender will offer you to stay, and compare leading offers from across the market", 2)}
        <div style={isWide ? { width: "11px" } : { height: "16px" }} />
        {joinerDot()}
        {joinerDot()}
        {joinerDot()}
        {joinerDot()}
        {isWide && joinerDot()}
        <div style={isWide ? { width: "11px" } : { height: "16px" }} />
        {contentBox("/images/icon_save.png", "Choose to stay, or go", "Stay with your lender if you're happy with their deal, or go with a better offer and start saving", 3)}
      </div>
    )
  }

  const assessmentFactBox = (heading: string, text: string) => {
    return (
      <div className={styles.assessmentFactBox}>
        <Typography.H3 comp="benefit">{heading}</Typography.H3>
        <div style={{ height: "14px" }} />
        <Typography.P comp="content-p">
          <span style={{ color: "#4D4D4D" }}>{text}</span>
        </Typography.P>
      </div>
    )
  }

  const assessLoansSection = () => {
    return (
      <div style={{ marginTop: isWide ? "50px" : "35px" }}>
        <Typography.P comp="large">
          <div style={{ textAlign: "center" }}>In a few simple steps we estimate how much you could save on your home loan. We use Open Banking to assess 2,500+ interest rates from 39 leading lenders, including all the major banks. We check whether your current bank will offer you a better deal, and summarise the top offers from across the market.</div>
        </Typography.P>
        <div className={styles.assessmentFactBoxesGrid}>
          {assessmentFactBox("39", "lenders, including all the majors")}
          {assessmentFactBox("130+", "home loan products")}
          {assessmentFactBox("2,500+", "interest rates")}
          {assessmentFactBox("8", "cashback offers")}
        </div>
      </div>
    )
  }

  const lookingForFirstLoan = () => {
    return (
      <div className={styles.lookingForFirstLoanContainer}>
        <div style={{ padding: isWide ? "36px" : "28px" }}>
          <Typography.H3 comp="benefit">Looking for your first home loan?</Typography.H3>
          <div style={{ height: "30px" }} />
          <Typography.P comp="large">
            <div style={{ color: "#4D4D4D" }}>
              We make it easy by comparing the all the major banks and top lenders in one place.{" "}
              <Link to="/home-loans" style={{ textDecoration: "underline", color: "#F84283" }}>
                Explore offers for first home buyers here.
              </Link>
            </div>
          </Typography.P>
        </div>
        <LazyLoadImage src="/images/home_loans/landing-page-first-home-image.png" style={{ width: "100%", borderRadius: "0 0 24px 24px" }} />
      </div>
    )
  }

  const tickLine = (text: string) => {
    return (
      <div className={styles.tickLine}>
        <LazyLoadImage src="/images/icon_tick_pink.png" style={{ width: "30px", marginRight: "22px" }} />
        <Typography.P comp="large">
          <div style={{ color: "#4D4D4D" }}>{text}</div>
        </Typography.P>
      </div>
    )
  }

  const newToStayOrGo = () => {
    return (
      <div className={styles.newToStayOrGoContainer}>
        <Typography.H3 comp="benefit">New to Stay or Go?</Typography.H3>
        <div style={{ height: "30px" }} />
        <Typography.P comp="large">
          <div style={{ color: "#4D4D4D" }}>Sign up with us to receive the following benefits:</div>
        </Typography.P>
        {tickLine("Access a better deal without needing to change banks")}
        {tickLine("Explore what you could save by changing banks")}
        {tickLine("Get an alert when your deal is no longer competitive")}
      </div>
    )
  }

  return (
    <div>
      <div className={styles.pageContentContainer}>
        {/* <ReturnLinkChain
          style={{ marginBottom: "20px", display: "flex" }}
          linkChain={[]}
          currentLocationItem={{name: "Home Loans"}}
        /> */}
        {topSection()}
        {sectionTextDivider("We simplify your home loan search, for free.", "Renegotiating with your lender can be difficult, and refinancing to a new one even harder. We take the hard work out of the home loan search process with 3 easy steps.", { marginTop: isWide ? "128px" : "65px" })}
        {simplifySearchSection()}
        {sectionTextDivider("How we assess top home loans", "In a nutshell, we crunch a lot of numbers", { marginTop: isWide ? "146px" : "80px" })}
        {assessLoansSection()}
        <div className={styles.lastContentSectionContainer}>
          {lookingForFirstLoan()}
          {!isWide && <div style={{ height: "40px" }} />}
          {newToStayOrGo()}
        </div>
        <FeaturedIn />
      </div>

      {/* {!props.indexPage && faqContent.length > 0 ? (
        <div>
          <div style={{ marginTop: "100px" }}>
            <ListHeaderProvider>{"FAQs"}</ListHeaderProvider>
            <Faq faqContent={faqContent} />
          </div>
          <HomeLoanDisclaimer isDisclaimerOpen isProvider />
        </div>
      ) : null} */}
    </div>
  )
}

const HomeLoansLandingPage = () => {
  return (
    <div className="container-center">
      <div className="home-container">
        <div>
          <HomeLoansLandingPageView />
        </div>
      </div>
    </div>
  )
}

export default HomeLoansLandingPage
